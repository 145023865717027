<script setup>
import { computed, onMounted, watch, ref } from 'vue';
import { useAiSummary } from '@/composables/use-ai-summary';
import { useEncounters } from '@/composables/use-encounters';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { useQueueItems } from '@/composables/use-queueing';
import { useRoute, useRouter } from 'vue-router';
import { useUi } from '@/composables/use-ui';
import { useVideoCall } from '@/composables/use-video-call';
import { useAuth } from '@/composables/use-mycure';
import { checkBrowserPermissions } from '@/utils/permissions';
import EncounterNavigator from '@/components/encounter/encounter-navigator.vue';
import GlobalSnackbar from '@/components/commons/global-snack';
import DoctorMeeting from '@/components/DoctorMeeting.vue';
import UserMenu from '@/components/commons/user-menu';
import useGlobalSnack from '@/components/commons/global-snack/composables';

const router = useRouter();
const route = useRoute();
const { signinWithToken } = useAuth();

const permissionDialog = ref(false);
onMounted(async () => {
  if (route.query?.token) {
    await signinWithToken(route.query.token);
  }
  await init();
  // HACK: reload the page once to fix the issue of unauthenticated requests
  if (globalThis.localStorage.getItem('reloaded-once') !== 'yes') {
    globalThis.location.reload();
    globalThis.localStorage.setItem('reloaded-once', 'yes');
  }

  checkBrowserPermissions(() => {
    permissionDialog.value = true;
  });
});

const { show: showSnack } = useGlobalSnack();

const { windowHeightNoToolbar, windowHeight } = useUi();

const patientId = computed(() => route.query.patient);
const encounterId = computed(() => route.query.encounter);
const facilityId = computed(() => route.query.facility);

const { drawerClasses } = useVideoCall();

const { list: listMedicalRecords, listMedicalRecordsHistory, medicalRecords } = useMedicalRecords();
  
const { 
  get: getCurrentEncounter,
  init: getPatientEncounters, 
  isEncounterActive, 
  update: updateEncounter, 
} = useEncounters();
const { encounterSummary, getEncounterSummary } = useAiSummary();
const { findItem: getQueueItem, updateItem: updateQueueItem } = useQueueItems();
const totalMedicalRecords = computed(() => medicalRecords.value.length);

watch(route, () => {
  init();
}, { deep: true });

function init () {
  const options = {
    patient: patientId.value,
    encounter: encounterId.value,
    facility: facilityId.value,
  };

  listMedicalRecords(options);
  listMedicalRecordsHistory({ facility: facilityId.value, patient: patientId.value });
  getPatientEncounters(patientId.value);
  getCurrentEncounter(encounterId.value);
  getEncounterSummary(encounterId.value);
}

function goBack () {
  const defaultRoute = 'dashboard-patient-id';
  let fromRoute = route.query.from || defaultRoute;
  if (fromRoute === route.name) fromRoute = defaultRoute;
  router.push({
    name: fromRoute,
    params: {
      id: route.query.patient,
    },
  });
}

async function onEndEncounter () {
  if (totalMedicalRecords.value === 0) {
    return globalThis.confirm('Encounter cannot be ended. There are no medical records created yet.');
  }

  if (!encounterSummary.value) {
    const res = globalThis.confirm('Summary is not yet generated. Do you wish to proceed without generating a summary?');
    if (!res) return;
  }

  const result = await globalThis.confirm('Do you want to end this encounter?');

  if (!result) return;

  const pendingQueue = await getQueueItem({ encounterId: encounterId.value });

  if (pendingQueue?.id) {
    // TODO: show modal for handling
    await updateQueueItem(pendingQueue.id, { finish: true });
  }

  await updateEncounter(encounterId.value, { finish: true });

  showSnack('Encounter ended successfully!');
}
</script>

<template>
<div data-theme="easyjoey" class="flex flex-col bg-[#f5f5f5]" :style="{ minHeight: `${windowHeight}px` }">
  <dialog id="dialog" :class="`modal ${permissionDialog ? 'modal-open' : ''}`">
    <div method="dialog" class="modal-box">
      <div class="flex justify-between">
        <span class="text-xl font-medium">Notice</span>
        <button
          class="btn btn-sm btn-circle btn-ghost"
          @click="permissionDialog = false"
        >
          <i class="las la-times text-2xl text-red-400"></i>
        </button>
      </div>
      <div class="pb-10 flex flex-col gap-6 mt-5">
        <p class="text-base">For the best demo experience please allow the browser to access your camera and microphone to start the video call.</p>
        <p class="text-base">If you are using Google Chrome click allow in the pop-up presented or grant it manually by clicking on the icon on the uppper left part of your browser as seen in the image below.</p>
        <img src="../assets/images/permission.png" alt="Permissions Image" class="rounded-lg">
      </div>
    </div>
  </dialog>

  <global-snackbar />

  <!-- Header -->
  <header
    class="w-full navbar flex bg-white border-solid border-1 border-b justify-between items-center sticky top-0 z-20 h-16">
    <div class="flex gap-2 items-center">
      <nuxt-link
        class="btn btn-square text-primary btn-ghost text-2xl"
        @click="goBack"
      >
        <i class="las la-arrow-left text-primary"></i>
      </nuxt-link>
      <img class="w-[140px]" src="../assets/images/parmazip/logo-with-text.svg" alt="EasyJoey Logo">
      <!-- <img class="w-[140px]" src="../assets/images/easyjoey-logo.png" alt="EasyJoey Logo"> -->
    </div>
    <div>
      <encounter-navigator/>
    </div>
    <div class="gap-2">
      <template v-if="isEncounterActive">
        <button 
          class="btn btn-sm btn-outline btn-primary" 
          :disabled="!isEncounterActive"
          @click="onEndEncounter"
        >
          <i class="las la-check-circle text-2xl text-primary"></i>
          End Consultation
        </button>
        <!-- <button 
          class="btn btn-sm btn-outline btn-primary" 
        >
          <i class="las la-check-circle text-2xl text-primary"></i>
          End Consultation
        </button> -->
      </template>
      <template v-else>
        <div class="badge badge-lg badge-primary badge-outline">
          <i class="las text-primary la-check text-xl mr-1"></i>
          Consultation Ended
        </div>
      </template>
      <user-menu />
    </div>
  </header>

  <div class="flex flex-col">
    <main>
      <div class="flex w-full">
        
        <div class="w-full">
          <NuxtPage />
        </div>

        <div :class="`${drawerClasses}`">
          <div 
            class="flex flex-col w-full border border-l-[#d8d8d8] bg-white overflow-y-scroll p-4"
            :style="{ height: `${windowHeightNoToolbar}px` }"
          >
            <!-- <DoctorMeeting :room="patientId" /> -->
            <DoctorMeeting room="parmazip-consult-demo" />
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
</template>
  
